<template>
    <div class="upload">
        <van-uploader
        v-model="imgList"
        :max-count="maxCount"
        multiple
        :after-read="afterRead"
        :before-delete="beforeDelete"
        />
    </div>
</template>

<script>
    import { upload } from '@/api'
    export default {
        props: {
            value: Array,
            maxCount: {
                type: Number,
                default: 9
            }
        },
        mounted(){
            if(this.value.length !== this.imgList.length){
                this.value.forEach(item => {
                    this.imgList.push({
                        url: item
                    })
                    this.reallyImgList.push(item)
                })
            }
        },
        watch: {
            value(value){
                if(value.length !== this.imgList.length){
                    value.forEach(item => {
                        this.imgList.push({
                            url: item
                        })
                        this.reallyImgList.push(item)
                    })
                }
            },
            reallyImgList(value){
                if(value.length !== this.value.length){
                    this.$emit('input', value)
                }
            }
        },
        data(){
            return {
                imgList: [],
                reallyImgList: []
            }
        },
        methods:{
            async afterRead(file) {
                if (file && file.length) {
                    file.forEach(async (item) => {
                        await this.upload(item)
                    })
                }else{
                    await this.upload(file)
                }
            },
            async upload(file){
                file.status = 'uploading'
                file.message = '上传中...'
                let fd = new FormData()
                fd.append("file", file.file)
                const res = await upload(fd)
                file.status = 'success'
                file.message = '上传成功'
                this.reallyImgList.push(res.data.url)
            },
            beforeDelete(file, index){
                this.reallyImgList.splice(index.index, 1)
                return true
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>